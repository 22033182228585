.App {
  text-align: center;
  font-size: 1.5rem;
  overflow-x: hidden;
}

.border-dashed {
  border-style: dashed;
  border-color: #e6e6e6;
  border-width: 2px;
}

.btn-blue {
  color: #39c0ed !important;
  box-shadow: none !important;
}

.btn-blue:hover {
  color: white !important;
}

.btn-white {
  color: white !important;
}

.btn-white:focus {
  box-shadow: none !important;
}

input {
  font-size: 21px;
  padding: 4px;
}

input:focus {
  outline: none;
}

@media (max-width: 412px) {
  input {
    font-size: 18px;
  }
}
