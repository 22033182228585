.icon-hover {
  cursor: pointer;
}

@media (max-width: 1024px) {
  .scan-container {
    width: auto !important;
  }
}
@media (max-width: 576px) {
  .scan-container {
    width: 20rem !important;
    height: 32rem !important;
  }
}
@media (max-width: 320px) {
  .scan-container {
    width: 16rem !important;
    height: 31rem !important;
  }
}
.scan-container img {
  width: 100%;
  max-width: fit-content;
  opacity: 0.5;
}
img.thumbnail {
  width: 100%;
  max-width: 32rem;
}