.footer{
  padding: 50px 0 70px;
  position: relative;
}
.bg_full{
  background-image: url('../../../../public/assets/images/footer_back.png');
}
.fo_link a{
  color: #aeb6b7;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  white-space: nowrap;
}
.fo_link a:before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 25px;
  background-color: #aeb6b7;
}
.fo_link a:first-child{
  padding-left: 0;
  margin-left: 0;
}
.fo_link a:first-child:before{
  display: none;
}
.footer_links .fo_link{
  padding-top: 10px;
}
.footer_links .fo_link:first-child{
  padding-top: 0;
}
img.lang_flag{
  height: 16px;
  width: 24px;
}
.footer_links{
  padding-top: 30px;
}
.footer .lang_txt{
  color: #fff;
  font-size: 14px;
}
.copy_txt{
  color: var(--theme-blue);
  padding: 15px 0;
}
.footer_block{
  position: relative;
  z-index: 1;
}
.fo_lang {
  position: relative;
}