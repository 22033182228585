@media (max-width:1920px){
    .banner_title{
        font-size: 60px;
    }
    .desc {
        font-size: 32px;
    }
    .banner_section .btn {
        margin-top: 40px;
    }
    .btn{
        font-size: 32px;
    }
    .section_title,.normal_title{
        font-size: 32px;
    }
    .product_title, .prod_price{
        font-size: 38px;
    }
    .image_with_content .list_tit, .image_with_content .list_desc, .image_with_content ::marker{
        font-size: 28px;
    }
    .image_with_content ._cont {
        padding-top: 80px;
    }
    .driving_abroad_section {
        padding: 80px 0 80px;
    }
    .img_cont {
        padding: 100px 50px 100px;
    }
    .products_section {
        padding: 80px 0 120px;
    }
    .driving_abroad_block {
        padding-top: 80px;
    }
}
@media (max-width:1600px){
    .banner_title {
        font-size: 50px;
    }
    .desc {
        font-size: 30px;
    }
    .btn {
        font-size: 30px;
    }
    .driving_abroad_section {
        padding: 60px 0 60px;
    }
    .driving_abroad_block {
        padding-top: 60px;
    }
    .small_desc {
        font-size: 22px;
    }
    .product_title, .prod_price {
        font-size: 34px;
    }
    .prod_tit_price {
        padding-top: 40px;
    }
    .prod_btn {
        padding-top: 40px;
    }
    .image_with_content ._cont {
        padding-top: 60px;
    }
    .section_title, .normal_title {
        font-size: 30px;
    }
    .customers_section {
        padding: 60px 0 60px;
    }
    .description_section {
        padding: 60px 0 80px;
    }
    .header ._logo, .footer ._logo {
        height: 100px;
    }
    .image_with_content .list_tit, .image_with_content .list_desc, .image_with_content ::marker {
        font-size: 24px;
    }
    .header .menu_list.drop_down {
        padding-right: 40px;
    }
    .check_img img {
        height: 40px;
    }
    .img_cont {
        padding: 80px 50px 80px;
    }
    .image_with_content .cont_max {
        max-width: 80%;
    }
}
@media (max-width:1400px){
    .banner_title {
        font-size: 42px;
    }
    .header ._logo, .footer ._logo {
        height: 80px;
    }
    .header .menu_list.drop_down {
        padding-right: 20px;
    }
    .header .menu_list.drop_down {
        padding-right: 10px;
    }
    .font_18 {
        font-size: 16px;
    }
    .btn_small{
        font-size: 16px;
    }
    .form_select .form-select{
        font-size: 16px;
    }
    .desc {
        font-size: 26px;
    }
    .section_title, .normal_title {
        font-size: 26px;
    }
    .check_img img {
        height: 30px;
    }
    .small_desc {
        font-size: 20px;
    }
    .products_section {
        padding: 60px 0 80px;
    }
    .product_title, .prod_price {
        font-size: 28px;
    }
    .btn {
        font-size: 26px;
        padding: 6px 25px;
    }
    .image_with_content .list_tit, .image_with_content .list_desc, .image_with_content ::marker {
        font-size: 22px;
    }
    .image_with_content .btn {
        margin-top: 60px;
    }
    .image_with_content ol li {
        padding-top: 30px;
    }
    .cus_imgs ._img {
        height: 40px;
        margin: 15px 0 15px;
    }
    .font_20 {
        font-size: 18px;
    }
}
@media (max-width:1200px){
    .footer .form_select .form-select {
        font-size: 14px;
    }
    .footer .form_select .select_block {
        padding-left: 5px;
    }
    .footer ._flag img {
        height: 14px;
    }
    .footer .form_select {
        padding: 8px 8px 8px 0;
    }
}
@media (max-width:1024px){
    .banner_title {
        font-size: 36px;
    }
    .banner_section .image {
        height: 600px;
    }
    .desc {
        font-size: 22px;
    }
    .banner_section .btn {
        margin-top: 30px;
    }
    .banner_section .desc {
        padding-top: 20px;
    }
    .btn {
        font-size: 20px;
        padding: 4px 20px;
    }
    .banner_section .ban_cont {
        bottom: 80px;
    }
    .header ._logo, .footer ._logo {
        height: 70px;
    }
    .driving_abroad_section {
        padding: 40px 0 40px;
    }
    .driving_abroad_section .img_tit {
        padding-top: 20px;
    }
    .driving_abroad_block {
        padding-top: 40px;
    }
    .products_section {
        padding: 40px 0 40px;
    }
    .product_grid {
      padding: 20px 10px 20px 10px;
    }
    .product_title, .prod_price {
        font-size: 24px;
    }
    .product_grid.active {
        padding: 20px 10px 20px 10px;
    }
    .prod_tit_price {
        padding-top: 30px;
    }
    .prod_det {
        padding-top: 20px;
    }
    .small_desc {
        font-size: 18px;
    }
    .prod_det .small_desc {
        padding-top: 10px;
    }
    .prod_btn {
        padding-top: 30px;
    }
    .products_grids .p_col {
        padding: 0 15px;
    }
    .products_grids .p_row {
        margin: 0 -15px;
    }
    .products_grids {
        padding-top: 40px;
    }
    .section_title, .normal_title {
        font-size: 22px;
    }
    .image_with_content ._cont {
        padding-top: 40px;
    }
    .image_with_content .list_tit, .image_with_content .list_desc, .image_with_content ::marker {
        font-size: 20px;
    }
    .image_with_content .btn {
        margin-top: 40px;
    }
    .image_with_content ol li {
        padding-top: 20px;
    }
    .customers_section {
        padding: 40px 0 40px;
    }
    .description_section {
        padding: 40px 0 40px;
    }
    .footer {
        padding: 40px 0 40px;
    }
    .font_20 {
        font-size: 16px;
    }
    .image_with_content .cont_max {
        max-width: none;
    }
    .img_cont {
        padding: 60px 40px 60px;
    }
    .img_grids:before{
        top: 45%;
    }
}
@media (max-width:992px){
  .product_grid {
    text-align: center;
  }
}
@media (max-width:900px){
    .img_grids:before {
        top: 5px;
        width: 65px;
        height: 99%;
        left: 50%;
        transform: translateX(-50%);
        right: auto;
    }
    .img_grids ._col:nth-child(3){
        background-color: #fff;
    }
    .driving_abroad_section .section_title {
        position: relative;
        z-index: 10;
    }
    .footer_block .row{
        text-align: center;
    }
    .fo_link ._link {
        display: block !important;
    }
    .fo_link ._link {
        padding-left: 0;
        margin-left: 0;
        padding-top: 15px;
    }
    .fo_link ._link:first-child {
        padding-top: 0;
    }
    .fo_link ._link:before{
        content: none;
    }
    .footer .lang_txt {
        width: 100%;
    }
    .copy_txt {
        text-align: center;
    }
    .footer_block ._col:last-child {
        text-align: center !important;
    }
    .products_grids .p_col {
        padding: 0;
    }
    .products_grids .p_row {
        margin: 0;
    }
    .d-flex {
        justify-content: center;
    }
    .driving_abroad_block .img_tit{
        display: none;
    }
    ._mobile.small_desc {
        display: block;
    }
    .img_grids ._col{
        margin-top: 30px;
    }
    .img_grids ._col:first-child{
        margin-top: 0;
    }
    .check_img {
        left: 20%;
    }
    .customers_section .cus_imgs .col-lg-4 {
        width: 50%;
        text-align: center;
    }
    .customers_section .cus_imgs{
        padding-top: 30px;
    }
    .footer_block .menu_list {
        direction: ltr;
    }
    .footer .form_select {
        padding: 8px 8px 8px 6px;
    }
    .footer_block ._col{
        margin-top: 30px;
    }
    .footer_block ._col:first-child{
        margin-top: 0;
    }
    .image_with_content .bg_full {
        width: 100%;
        height: 350px;
    }
    .image_with_content ._col {
        margin-left: 0;
        margin-top: 380px;
        margin-bottom: 40px;
    }
    .img_cont {
        padding: 0 36px;
    }
    .header .col-lg-4{
        width: 20%;
    }
    .header .col-lg-8{
        width: 80%;
    }
    .header ._email{
        display: none !important;
    }
    .products_grids .p_col {
        margin-top: 30px;
    }
    .products_grids .p_col:first-child{
        margin-top: 0;
    }
}
@media (max-width:740px){
    .banner_section .image {
        height: 450px;
    }
    .banner_title {
        font-size: 30px;
    }
    .desc {
        font-size: 16px;
    }
    .btn {
        font-size: 16px;
    }
    .driving_abroad_section {
        padding: 30px 0 30px;
    }
    .section_title, .normal_title {
        font-size: 18px;
    }
    .small_desc {
        font-size: 16px;
    }
    .products_section {
        padding: 30px 0 30px;
    }
    .product_title, .prod_price {
        font-size: 20px;
    }
    .image_with_content .list_tit, .image_with_content .list_desc, .image_with_content ::marker {
        font-size: 16px;
    }
    .image_with_content ._cont {
        padding-top: 20px;
    }
    .image_with_content .btn {
        margin-top: 20px;
    }
    .customers_section {
        padding: 30px 0 30px;
    }
    .customers_section .cus_imgs {
        padding-top: 20px;
    }
    .cus_imgs ._img {
        height: 30px;
    }
    .img_cont {
        padding: 0 12px;
    }
    .image_with_content .container-fluid {
        max-width: 540px;
    }
    .check_img {
        left: 10%;
    }
    .header ._logo, .footer ._logo {
        height: 60px;
    }
    .btn_small {
        font-size: 14px;
        border: 1px solid #00b3e7;
        padding: 4px 15px;
    }
    .header ._flag img {
        height: 14px;
    }
    .form_select .form-select {
        font-size: 14px;
        background-size: 8px 8px;
        padding-right: 20px;
        background-position: right 0.4rem center;
    }
    .font_18 {
        font-size: 14px;
    }
    .header .menu_list {
        padding-left: 15px;
    }
    .image_with_content .cont_max {
        text-align: center;
    }
}
@media (max-width:500px){
    .header .menu_list._user ._menu{
        display: none !important;
    }
    .header .menu_list {
        padding-left: 10px;
    }
    .check_img {
        left: 12px;
    }
    .footer .form_select {
        padding: 6px 6px 6px 0;
        border: 1px solid #00b3e7;
    }
    .check_img img {
        height: 25px;
    }
}