:root {
    --font-arkiv: 'Arkiv';
    --theme-blue:#1f2855;
}
body{
    font-family: 'Open Sans';
}
* {
    margin: 0px;
    padding: 0px;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
img{
    max-width: 100%;
}
.header{
  /* background-color: #37468c; */
}
.header #responsive-navbar-nav{
  color: white;
}
.header ._logo,.footer ._logo{
  height: 115px;
  object-fit: contain;
}
a, button {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    transition: all 0.4s ease;
}
.menu_list i{
    line-height: 1.5;
    color: #00b3e7;
}
.header .menu_list:last-child {
    padding-left: 0;
}
.menu_list a:hover ._menu{
    color: #00b3e7 !important;
}
.menu_list ._menu{
    padding-right: 10px;
    transition: all 0.4s ease;
}
.header .menu_list{
    padding-left: 20px;
    vertical-align: middle;
}
.btn_small{
    display: inline-block;
    color: #fff;
    border: 2px solid #00b3e7;
    background-color: transparent;
    padding: 4px 20px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
}
.btn_small:hover{
    color: #00b3e7;
}
.font_18{
    font-size: 18px;
}
.font-weight-500{
    font-weight: 500;
}
/* .form_select .form-select {
    font-size: 18px;
    font-weight: 500;
    background-image: url('../../../../public/assets/images/down_arrow.png');
    background-color: transparent;
    border: none;
    color: #fff;
    padding-left: 0;
    background-size: 12px 12px;
}
.form_select .form-select:focus{
    box-shadow: none;
}
.form_select .form-select option{
    color: #000;
}
.header .menu_list.drop_down {
    padding-right: 60px;
} */
.bg_full {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url() no-repeat 50% 50%;
    overflow: hidden;
    background-size: cover;
}

.home .header{
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 999;
    width: 100%;
}
