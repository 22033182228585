.products-container {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}

.products-container:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.prod_icon svg {
  color: #00b3e7;
}

@media (max-width: 1025px) {
  .products-container {
    width: 35rem !important;
  }
}
@media (max-width: 576px) {
  .products-container {
    width: 20rem !important;
  }
}
@media (max-width: 320px) {
  .products-container {
    width: 16rem !important;
  }
}
