.banner_section .bg_full{
  background-image: url('../../../../public/assets/images/banner_back_flip_reduced.jpg');
}

.banner_section .image {
  position: relative;
  height: 860px;
}
.banner_title{
  font-size: 72px;
  color: #fff;
  line-height: 1.2;
  font-family: var(--font-arkiv);
  font-weight: bold;
}
.banner_title span{
  color: #66d3ed;
  display: block;
}
.banner_content {
  position: absolute;
  top: 60%;
  right: 0;
  text-align: right;
  transform: translate(0, -50%);
  width: 100%;
}
.desc{
  font-size: 36px;
  line-height: 1.2;
}
.banner_section .desc{
  padding-top:30px;
}
.home .btn{
  display: inline-block;
  font-size: 36px;
  background-color: #00b3e7;
  border: 2px solid #00b3e7;
  color: #fff;
  padding: 8px 50px;
  border-radius: 5px;
  font-weight: 600;
  font-family: var(--font-arkiv);
}
.banner_section .btn:hover{
  color: #fff !important;
}
.home .btn:hover{
  color: #00b3e7 !important;
  background-color: transparent;
  border: 2px solid #00b3e7 !important;
}
.banner_section .btn{
  margin-top: 50px;
}

.section_title{
  font-size: 36px;
  color: #1f2855;
  font-weight: bold;
  font-family: var(--font-arkiv);
}
.driving_abroad_section{
  padding: 130px 0 120px;
  overflow: hidden;
}
.small_desc{
  font-size: 24px;
  color: #1f2855;
}
.img_grid{
  position: relative;
}
.check_img {
  position: absolute;
  top: 0;
  left: 0;
}
.check_img img{
  height: 50px;
}
.img_grids {
  position: relative;
  z-index: 1;
}
.img_grid._2 .grid_img{
  padding-top: 85px;
}
.img_grids_inner{
  position: relative;
}
.img_grids:before {
  content: '';
  position: absolute;
  background-image: url('../../../../public/assets/images/back_dot.png');
  top: 50%;
  left: -50%;
  right: -50%;
  transform: translateY(-50%);
  height: 65px;
  background-size: contain;
  background-repeat: repeat;
}
.driving_abroad_block{
  padding-top: 110px;
}
.driving_abroad_section .img_tit {
  padding-top: 30px;
}
.image_with_content .list_tit{
  font-weight: bold;
  font-family: var(--font-arkiv);
}
.image_with_content ._cont ::marker{
  font-weight: bold;
  color: #00b3e7;
}
.image_with_content ol li{
  padding-top: 60px;
}
.image_with_content .list_tit,.image_with_content .list_desc,.image_with_content ::marker{
  font-size: 30px;
  color: var(--theme-blue);
  display: inline-block;
}
.image_with_content ol, .image_with_content ul {
  padding-right: 2rem;
}
.image_with_content ol li:first-child{
  padding-top: 0;
}
.image_with_content ._cont{
  padding-top: 120px;
}
.image_with_content .btn{
  margin-top: 90px;
}
.image_with_content{
  position: relative;
}
.image_with_content .bg_full{
  width: 50%;
}
.image_with_content ._col{
  margin-left: 50%;
  padding: 0;
}
.img_cont{
  padding:150px 50px 125px;
}
.image_with_content .cont_max{
  max-width: 70%;
}
.normal_title{
  font-size: 36px;
}
.customers_section .normal_title{
  color: var(--theme-blue);
  font-weight: bold;
}
.cus_imgs ._img{
  height: 50px;
  margin: 25px 0 25px;
}
.customers_section{
  padding: 75px 0 75px;
  background-color: #e1e6e6;
}
.font_20{
  font-size: 20px;
  line-height: 1.2;
}
.description_section ._desc{
  color: var(--theme-blue);
}
.description_section p{
  padding-top: 30px;
  margin-bottom: 0;
}
.description_section ._desc p:first-child{
  padding-top: 0;
}
.description_section{
  padding: 90px 0 100px;
}

._mobile.small_desc{
  display: none;
}
.driving_abroad_block .mobile_view{
  display: none;
}
.img_cont ol{
  list-style-position: inside;
}
.home #responsive-navbar-nav.show{
  background-color: #1f2855;
}
.home #responsive-navbar-nav.collapsing{
  background-color: #1f2855;
}