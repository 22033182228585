.products-container {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}

.products-container:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

@media (max-width: 1025px) {
  .products-container {
    width: 35rem !important;
  }
}
@media (max-width: 576px) {
  .products-container {
    width: 20rem !important;
  }
}
@media (max-width: 320px) {
  .products-container {
    width: 16rem !important;
  }
}
.products_section{
  padding: 90px 0 150px;
  background-color: #eef0f5;
}
.product_title,.prod_price{
  font-size: 42px;
  line-height: 1.2;
  font-family: var(--font-arkiv);
  font-weight: bold;
}
.prod_price{
  color: #00b3e7;
  padding-right: 5px;
}
.product_title {
  color: var(--theme-blue);
}
.prod_tit_price ._row{
  display: flex;
}
.prod_det{
  padding-top: 30px;
}   
.prod_det .small_desc{
  padding-top: 20px;
}
.prod_det .small_desc:first-child{
  padding-top: 0;
}
.prod_det .small_desc:nth-child(2){
  color:#00b3e7;
}
.prod_btn{
  padding-top: 60px;
}
.prod_icon  img{
  height: 70px;
}
.prod_tit_price{
  padding-top: 50px;
}
.product_grid{
  text-align: initial;
  padding: 50px 30px 50px 40px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 9px 7px 15px rgb(0 0 0 / 18%);
}
.products_grids .p_col{
  padding: 0 25px;
}
.products_grids .p_row{
  margin: 0 -25px;
}
.product_grid.active{
  padding: 70px 30px 70px 40px;
  background-color: #e0f3f5;
}
.products_grids{
  padding-top: 60px;
}
